import styles from './Profile.module.less';

import React from "react";
import {Button} from "antd";
import Api from "../api/Api";
import ProfileOption from './forms/ProfileOption';
import Spinner from "../utils/Spinner/Spinner";
import {notificationError} from "../utils/Notification/Notification";
import BirthyearProfileOption from "./forms/BirthyearProfileOption";
import logo from "../assets/SpeakUp_LOGO.svg";

export default class Profile extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            sessionId: sessionStorage.getItem('sessionId'),
            current: 0,
            selected: {}
        };
    }

    async componentDidMount() {
        const features = await Api.getProfileFeatures();
        this.setState({
            profileFeatures: features
        });
    }

    saveProfileOptions = options => {
        if (this.state.sessionId) {
            Api.updateUser(this.state.sessionId, options)
                .then(() => {
                    this.props.history.push({
                        pathname: '/bingo',
                        state: {
                            scores: this.props.location.state?.scores
                        }
                    });
                })
                .catch(() => {
                    notificationError('There was a problem saving your settings');
                });
        }
    };

    next = (OPTIONS) => {
        const {current} = this.state;
        const idx = current === OPTIONS.length - 1 ? 0 : current + 1;
        this.setState({
            current: idx
        });
    };

    prev = (OPTIONS) => {
        const {current} = this.state;
        const idx = current === 0 ? OPTIONS.length - 1 : current - 1;
        this.setState({
            current: idx
        })
    };

    setProfileOption = (optionKey, optionId) => {
        const {selected} = this.state;
        if (selected[optionKey] !== optionId) {
            selected[optionKey] = optionId;
            this.setState({selected});
        }
    };

    render() {
        if (!this.state.profileFeatures) {
            return <Spinner/>
        }
        const OPTIONS = [
            {
                id: 'occupation_id',
                title: 'Occupation',
                data: this.state.profileFeatures.occupations
            },
            {
                id: 'gender_id',
                title: 'Gender',
                data: this.state.profileFeatures.genders
            },
            {
                id: 'fitness_level_id',
                title: 'Fitness Level',
                data: this.state.profileFeatures.fitnessLevels
            },
            {
                id: 'team_id',
                title: 'Team',
                data: this.state.profileFeatures.teams
            },
            {
                id: 'birthyear'
            }
        ];

        return (
            <div className={styles.profile}>
                <div className={styles.header}>
                    Where do you sit?
                </div>
                <div className={styles.container}>
                    <div className={styles.description}>
                        <p>
                            By adding just a few more
                            details you can <b>check your
                            overall wellbeing score</b>.
                        </p>
                        <p>
                            Hey, why not get a work
                            buddy involved and help
                            each other along the way?
                        </p>
                        <p>
                            (Fun challenge at the end!)
                        </p>
                    </div>
                    <div className={styles.content}>
                        <div className={styles.options}>
                            {OPTIONS[this.state.current].id !== 'birthyear'
                                ?
                                <ProfileOption
                                    index={this.state.current + 1}
                                    count={OPTIONS.length}
                                    key={OPTIONS[this.state.current].id}
                                    {...OPTIONS[this.state.current]}
                                    callback={this.setProfileOption}
                                    selected={this.state.selected[OPTIONS[this.state.current].id]}
                                />
                                :
                                <BirthyearProfileOption
                                    index={this.state.current + 1}
                                    count={OPTIONS.length}
                                    key={OPTIONS[this.state.current].id}
                                    id={OPTIONS[this.state.current].id}
                                    callback={this.setProfileOption}
                                    selected={this.state.selected[OPTIONS[this.state.current].id]}
                                />
                            }
                        </div>
                        <div className={styles.actions}>
                            <Button
                                disabled={this.state.current === 0}
                                onClick={() => this.prev(OPTIONS)}
                            >Prev</Button>
                            {this.state.current === OPTIONS.length - 1 ?
                                <Button
                                    disabled={!this.state.selected[OPTIONS[this.state.current].id]}
                                    onClick={() => this.saveProfileOptions(this.state.selected)}
                                >Submit</Button>
                                :
                                <Button
                                    disabled={this.state.current === OPTIONS.length - 1 || !this.state.selected[OPTIONS[this.state.current].id]}
                                    onClick={() => this.next(OPTIONS)}
                                >Next</Button>
                            }
                        </div>
                    </div>
                </div>
                <div className={styles.logo}>
                    <img alt='' src={logo} />
                </div>
            </div>
        );
    }

}