import React from 'react';
import {connect} from 'react-redux';
import {notificationError} from "../../modules/utils/Notification/Notification";
import Spinner from "../../modules/utils/Spinner/Spinner";
import * as actions from '../actions';

export default Component => {
    const mapStateToProps = state => {
        return {
            categoriesLoading: state.categories.categoriesLoading,
            categories: state.categories.categories
        };
    };

    const mapDispatchToProps = dispatch => {
        return {
            onFetchCategories: () => dispatch(actions.fetchCategories()),
        };
    };

    class Wrapper extends React.Component {
        componentDidMount() {
            if (this.props.categories || this.props.categoriesLoading) {
                return;
            }
            this.props.onFetchCategories().catch(err => notificationError(err.message));
        }

        render() {
            if (this.props.categories === null || this.props.categoriesLoading) {
                return <Spinner/>;
            }
            return (
                <Component {...this.props} />
            );
        }
    }

    return connect(mapStateToProps, mapDispatchToProps)(Wrapper);
};
