import {NavLink} from "react-router-dom";
import logo from "../../assets/SpeakUp_LOGO.svg";
import dashboardCharacter from "../../dashboard/assets/dashboard-character.svg";
import styles from './ExtraSummary.module.less';

import {Button} from "antd";
import React, {Component} from "react";
import {withRouter} from "react-router";

class ExtraSummary extends Component {

    render() {
        return (
            <div className={styles.dashboard}>
                <div className={styles.layout}>
                    <div className={styles.left}>
                        <div className={styles.content}>
                            <h1>Nice work <br/>so far.</h1>
                            <h2>The following few questions help you to reflect on how things went yesterday, you can also leave some feedback.</h2>
                            <h2>Your voice is important.</h2>
                            <div className={styles.action}>
                                <Button onClick={() => this.props.history.push('/questions/regular')}>
                                    Let's do this
                                </Button>
                            </div>
                        </div>
                        <div className={styles.logo}>
                            <img alt='' src={logo} />
                        </div>
                    </div>
                    <div className={styles.image}>
                        <img alt='' src={dashboardCharacter}/>
                    </div>
                    <div className={styles.exit}>
                        <Button>
                            <NavLink to={'/dashboard'}>
                                Exit
                            </NavLink>
                        </Button>
                    </div>
                </div>
            </div>
        );
    }

}

export default withRouter(ExtraSummary);