import Api from "../../modules/api/Api";
import {createAction, CATEGORIES} from './types';

export const fetchCategories = () => dispatch => {
    dispatch(createAction(CATEGORIES.FETCH_CATEGORIES_START));

    return Api.getAllCategories()
        .then(categories => {
            dispatch(
                createAction(CATEGORIES.FETCH_CATEGORIES_SUCCESS, {
                    categories: categories.sort((a, b) => a.id > b.id ? 1 : -1)
                })
            );
        })
        .catch(error => {
            dispatch(createAction(CATEGORIES.FETCH_CATEGORIES_ERROR, {
                error: error
            }))
        });
};