import {CATEGORIES} from '../actions/types';

const initialState = {
    categories: null,
    categoriesLoading: false,
    categoriesError: false
};

export default (state = initialState, action) => {
    switch (action.type) {
        case CATEGORIES.FETCH_CATEGORIES_START:
            return {
                ...state,
                categoriesLoading: true
            };

        case CATEGORIES.FETCH_CATEGORIES_SUCCESS:
            return {
                ...state,
                categoriesLoading: false,
                categories: action.payload.categories
            };

        case CATEGORIES.FETCH_CATEGORIES_ERROR:
            return {
                ...state,
                categoriesLoading: false,
                categories: undefined,
                categoriesError: action.payload.error
            };

        default: {
            return state;
        }
    }
}