import {Authenticator, ForgotPassword, Greetings, SignUp} from "aws-amplify-react";

import React, {Component} from 'react';
import {Redirect, Route, Switch, withRouter} from "react-router";
import styles from './App.module.less';
import theme from './AuthenticatorTheme';
import WellBingo from "./bingo/WellBingo";
import Dashboard from "./dashboard/Dashboard";
import Profile from "./profile/Profile";
import QuestionsComponent from "./questions/QuestionsComponent";
import Feedback from "./summary/comment/Feedback";

class App extends Component {

    constructor(props) {
        super(props);

        this.handleAuthStateChange = this.handleAuthStateChange.bind(this);

        this.state = {
            authState: props.authState || null,
            authData: props.authData || null,
        };

        this.authConfig = {};
    }

    authenticatedRoutes = (
        <Switch>
            <Route exact path={`/`} render={() => <Redirect to={`/dashboard`}/>}/>
            <Route path={`/dashboard`} component={Dashboard}/>
            <Route path={`/questions`} component={QuestionsComponent}/>
            <Route path={`/feedback`} component={Feedback}/>
            <Route path={`/profile`} component={Profile}/>
            <Route path={`/bingo`} component={WellBingo}/>
        </Switch>
    );

    handleAuthStateChange(state, data) {
        if (state === 'verifyContact') {
            this.setState({authState: 'signedIn', authData: data});
        } else {
            this.setState({authState: state, authData: data});
        }
    }

    render() {
        const { authState } = this.state;
        const signedIn = authState === 'signedIn';
        if (signedIn) {
            return this.authenticatedRoutes;
        }
        return (
            <div className={styles.app}>
                <Authenticator
                    hide={[Greetings, SignUp, ForgotPassword]}
                    hideDefault={false}
                    onStateChange={this.handleAuthStateChange}
                    theme={theme}
                    children={[]}
                />
            </div>
        );
    }

}

export default withRouter(App);
