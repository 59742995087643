import styles from './TCup.module.less';

import React from 'react';
import Lottie from "react-lottie";
import shadow from './assets/tcup-summary-shadow.svg';
import background from './assets/tcup-summary-background.svg';

const WIDTH_RATIO = 0.93;

export default class TCup extends React.Component {

    constructor(props) {
        super(props);

        const height = 100 + (window.innerWidth * 0.2);

        const MAX_SIZE = window.innerHeight * 0.35;
        this.state = {
            scores: props.scores,
            height: height > MAX_SIZE ? MAX_SIZE : height,
            width: height > MAX_SIZE * WIDTH_RATIO ? MAX_SIZE * WIDTH_RATIO : height * WIDTH_RATIO,
        };

        this.onResize = this.onResize.bind(this);
    }

    componentDidMount() {
        window.addEventListener('resize', this.onResize, false);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.onResize, false);
    }

    onResize() {
        const MAX_SIZE = window.innerHeight * 0.35;
        const height = 100 + (window.innerWidth * 0.2);
        this.setState({
            height: height > MAX_SIZE ? MAX_SIZE : height,
            width: height > MAX_SIZE * WIDTH_RATIO ? MAX_SIZE * WIDTH_RATIO : height * WIDTH_RATIO,
        });
    }

    render() {
        const MIN_CATEGORY_HEIGHT = 5;
        let currentPosition = this.state.height - this.state.height * 0.04;
        const scores = this.state.scores.slice()
            .sort((a, b) => a.id - b.id)
            .reverse()
            .map(score => {
                const subTotal = (this.state.height - this.state.height * 0.1) * score.weight / 100;
                const height = score.value >= MIN_CATEGORY_HEIGHT ? subTotal * score.value / 100 : MIN_CATEGORY_HEIGHT;
                currentPosition -= height;
                return {
                    title: score.title,
                    height: height,
                    position: currentPosition,
                    color: score.color
                };
            }).reverse();

        return (
            <div className={styles.tcup}>
                <div className={styles.content} style={{width: this.state.width, height: this.state.height}}>
                    <svg width='100%' height='100%' className={styles.svgTCup}>
                        <mask id='mask'>
                            <image height={this.state.height} width='100%' xlinkHref={shadow}/>
                        </mask>
                        {scores.map((score, key) => (
                            <rect
                                key={key}
                                mask='url(#mask)'
                                className={styles.animated}
                                style={{height: score.height}}
                                width='100%'
                                height={score.height}
                                x='0'
                                y={score.position}
                                fill={score.color}
                            />
                        ))}
                    </svg>
                    <img alt='tcup-summary-shadow' src={background} className={styles.shadow}/>
                </div>
                {this.props.recognitionImage && <div className={styles.animation}>
                    <Lottie
                        width='100%'
                        height='100%'
                        isClickToPauseDisabled={true}
                        options={{
                            animationData: this.props.recognitionImage,
                            rendererSettings: {
                                preserveAspectRatio: 'xMidYMid meet'
                            }
                        }}
                    />
                </div>}
            </div>
        );
    }

}