import styles from './Feedback.module.less';

import React, {useState} from "react";
import {Button, Input, Rate} from "antd";
import {EditOutlined} from "@ant-design/icons";
import {withRouter} from "react-router";
import Api from "../../api/Api";
import {notificationError, notificationSuccess, notificationWarning} from "../../utils/Notification/Notification";

const Feedback = (props) => {

    const sessionId = sessionStorage.getItem('sessionId');

    if (!sessionId) {
        props.history.push('/');
    }

    const scores = props.history.location.state?.scores;
    const recognition = props.history.location.state?.recognition;
    const feedback = props.history.location.state?.feedback;

    const [rating, setRating] = useState(feedback?.rating);
    const [comment, setComment] = useState(feedback?.comment);
    const [loading, setLoading] = useState(false);

    const ratingRef = React.createRef();
    const textAreaRef = React.createRef();

    const handleOk = () => {
        const rating = ratingRef.current.state.value;
        const comment = textAreaRef.current.state.value;
        if (!rating) {
            notificationWarning('Please select your feedback rating!');
            return;
        }

        setLoading(true);
        Api.createComment(sessionId, comment, rating)
            .then(() => {
                notificationSuccess('Thanks for leaving your feedback');
                setComment(comment);
                setRating(rating);
                props.history.push({
                    pathname: '/questions/regular/summary',
                    state: {
                        scores,
                        recognition,
                        feedback: {
                            comment,
                            rating
                        }
                    }
                });
            })
            .catch((err) => {
                if (err.response?.status === 409) {
                    notificationError('You already left your feedback!');
                } else {
                    notificationError('Unable to save your comment!');
                }
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const handleCancel = () => {
        props.history.push({
            pathname: '/questions/regular/summary',
            state: {
                scores,
                recognition,
                feedback: {
                    comment,
                    rating
                }
            }
        });
    };

    const handleOnChange = e => {
        const {value} = e.target;
        setComment(value);
    }

    return (
        <div className={styles.feedback}>
            <div className={styles.header}>
                Use your voice
            </div>
            <div className={styles.modal}>
                <div className={styles.content}>
                    <div className={styles.header}>Your space to speak up anonymously...</div>
                    <div className={styles.rates}>
                        <div><EditOutlined/> Rate how work is going:</div>
                        <Rate
                            ref={ratingRef}
                            defaultValue={rating}
                            className={styles.rating}
                            disabled={rating}
                        />
                    </div>
                    <div className={styles.comment}>
                        <Input.TextArea
                            ref={textAreaRef}
                            placeholder={`Type what's on your mind in here...`}
                            autoSize={{minRows: 5, maxRows: 10}}
                            maxLength={220}
                            disabled={rating}
                            defaultValue={comment}
                            onChange={handleOnChange}
                        />
                        <div className={styles.textLimit}>
                            {comment?.length || 0} / 220
                        </div>
                    </div>
                    <div className={styles.footer}>
                        <Button key="submit" type="primary" loading={loading} disabled={rating} onClick={handleOk}>
                            Save
                        </Button>
                        <Button key="back" type='default' onClick={handleCancel} disabled={loading}>
                            Cancel
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );

}

export default withRouter(Feedback);