import thunk from 'redux-thunk';
import { applyMiddleware, compose, createStore } from 'redux';
import rootReducer from './reducers';

const middleware = [thunk];

const devTools =
  (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__()) ||
  (a => a);

export default (initialState = {}) =>
  createStore(
    rootReducer,
    initialState,
    compose(applyMiddleware(...middleware), devTools)
  );
