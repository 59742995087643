import styles from './WellBingo.module.less';

import React from "react";
import {Button} from "antd";
import {NavLink} from "react-router-dom";
import logo from '../assets/SpeakUp_LOGO.svg';
import wellBingo from './assets/wellBingo.svg';
import ScoresSVG from "./assets/Scores";
import OverallScoreSVG from "./assets/OverallScoreSVG";

export default class WellBingo extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            scores: this.props.location.state?.scores
        };
    }

    render() {
        const {scores} = this.state;
        const overallScore = scores
            ?.map(score => score.value * score.weight / 100)
            .reduce((sum, next) => sum + next) || 0;
        return (
            <div className={styles.bingo}>
                <div className={styles.left}>
                    <div className={styles.header}>
                        <div className={styles.bravo}>
                            Bravo!
                        </div>
                        <div>
                            <div>Great score on your overall wellbeing.</div>
                            <div>Keep up the good work and remember</div>
                            <div>to check back again soon.</div>
                        </div>
                    </div>
                    <div className={styles.scores}>
                        <div className={styles.scoresValues}>
                            <ScoresSVG
                                health={scores?.filter(s => s.title.toLowerCase() === 'health')[0]?.value || 0}
                                happiness={scores?.filter(s => s.title.toLowerCase() === 'happiness')[0]?.value || 0}
                                success={scores?.filter(s => s.title.toLowerCase() === 'success')[0]?.value || 0}
                            />
                        </div>
                        <div className={styles.overall}>
                            <div>
                                <OverallScoreSVG
                                    value={overallScore}
                                />
                            </div>
                        </div>
                    </div>
                    <Button shape='round' className={styles.finished}>
                        <NavLink to='/dashboard'>
                            Finished
                        </NavLink>
                    </Button>
                    <div className={styles.logo}>
                        <img alt='' src={logo}/>
                    </div>
                </div>
                <div className={styles.right}>
                    <div>
                        <img alt='' src={wellBingo}/>
                    </div>
                </div>
            </div>
        );
    }

}