import styles from './QuestionsCarousel.module.less';

import React, {Component} from 'react';
import {withRouter} from "react-router";
import {Spin} from 'antd';
import {find} from "lodash";
import Api from '../api/Api';
import QuestionView from './QuestionView';
import Spinner, {Indicator} from '../utils/Spinner/Spinner';
import withCategories from "../../redux/hoc/withCategories";

class QuestionsCarousel extends Component {

    constructor(props) {
        super(props);

        this.state = {
            sessionId: sessionStorage.getItem('sessionId'),
            userData: props.userData,
            answers: [],
            currentIndex: 0,
            savingAnswers: false
        };
    }

    isSavingAnswers(state) {
        this.setState({
            savingAnswers: state
        });
    }

    submitAnswers = (answers) => {
        this.isSavingAnswers(true);
        Api.saveAnswers(this.state.sessionId, answers)
            .then(() => this.showUserSummary())
            .catch(() => this.isSavingAnswers(false));
    };

    showUserSummary() {
        this.props.history.push('/questions/regular/summary');
    }

    selectAnswer = (questionId, questionAnswer) => {
        if (!this.state.savingAnswers) {
            this.saveAnswer(questionId, questionAnswer);

            if (this.state.answers.length === 9) {
                this.submitAnswers(this.state.answers)
            } else {
                const nextIndex = this.state.currentIndex + 1;
                if (nextIndex < this.props.questions.length) {
                    this.changeQuestion(nextIndex);
                }
            }
        }
    };

    saveAnswer(questionId, questionAnswer) {
        const answers = this.state.answers;
        const found = answers.find(ans => ans.questionId === questionId);
        if (found) {
            found.value = questionAnswer;
        } else {
            answers.push({questionId: questionId, value: questionAnswer});
        }
        this.setState({
            answers
        });
    }

    saveExtraAnswer(questionId, questionAnswer) {
        this.setState({
            extraAnswer: {
                questionId: questionId,
                value: questionAnswer
            }
        });
    }

    prev = () => {
        const current = this.state.currentIndex;
        const prev = current === 0 ? this.props.questions.length - 1 : current - 1;
        this.changeQuestion(prev);
    };

    next = () => {
        const current = this.state.currentIndex;
        const next = current === this.props.questions.length - 1 ? 0 : current + 1;
        this.changeQuestion(next);
    };

    changeQuestion = (index) => {
        this.setState({
            currentIndex: index,
        });
    };

    renderQuestions(currentIndex) {
        if (this.props.questions.length !== 0) {
            return (
                this.props.questions.map((question, key) => (
                    <QuestionView
                        key={question.id}
                        index={key}
                        question={question}
                        subcategory={find(this.props.categories.flatMap(c => c.Subcategories), {id: question.subcategory_id})}
                        callback={this.selectAnswer}
                        prev={this.prev}
                        next={this.next}
                        visible={this.props.questions[currentIndex].id === question.id}
                    />
                ))
            );
        }
    }

    render() {
        if (!this.state.sessionId) {
            this.props.history.push('/');
        }
        if (!this.props.questions) {
            return (
                <Spinner/>
            );
        }
        const currentIndex = this.state.currentIndex;
        return (
            <div className={styles.layout}>
                <Spin spinning={this.state.savingAnswers} size='large' indicator={<Indicator/>}>
                    <div className={styles.content}>
                        {this.renderQuestions(currentIndex)}
                    </div>
                </Spin>
            </div>
        );
    }
}

export default withRouter(withCategories(QuestionsCarousel));