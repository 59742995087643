import React from "react";
import {Redirect, Route, Switch} from "react-router";
import Api from "../api/Api";
import ExtraSummary from "../summary/extra/ExtraSummary";
import Summary from "../summary/regular/Summary";
import Spinner from "../utils/Spinner/Spinner";
import ExtraQuestion from "./ExtraQuestion";
import QuestionsCarousel from "./QuestionsCarousel";

export default class QuestionsComponent extends React.Component {

    constructor(props) {
        super(props);

        this.state = {};
    }

    componentDidMount() {
        this.getDailyQuestions();
    }

    getDailyQuestions() {
        Api.getDailyQuestions()
            .then(response => this.setState({
                questions: response.questions,
                extraQuestion: response.extraQuestion
            }))
            .catch(() => this.props.history.push('/dashboard'));
    }

    render() {
        if (!this.state.questions || !this.state.extraQuestion) {
            return <Spinner/>;
        }
        const ROOT_PATH = this.props.match.path;
        return (
            <Switch>
                <Route exact path={`${ROOT_PATH}/`} render={() => <Redirect to={`${ROOT_PATH}/extra`}/>}/>
                <Route path={`${ROOT_PATH}/extra`}>
                    <Route exact path={`${ROOT_PATH}/extra`} render={() => <ExtraQuestion question={this.state.extraQuestion}/>}/>
                    <Route path={`${ROOT_PATH}/extra/summary`} component={ExtraSummary}/>
                </Route>
                <Route path={`${ROOT_PATH}/regular`}>
                    <Route exact path={`${ROOT_PATH}/regular`} render={() => <QuestionsCarousel questions={this.state.questions}/>}/>
                    <Route path={`${ROOT_PATH}/regular/summary`} component={Summary}/>
                </Route>
            </Switch>
        );
    }

}