import styles from './BirthyearProfileOption.module.less';

import React, {useState} from "react";
import moment from "moment";
import {InputNumber} from "antd";

export default function BirthyearProfileOption(props) {

    const [selected, setSelected] = useState(props.selected);

    const onChange = (value) => {
        setSelected(value);
        props.callback(props.id, value);
    }

    return (
        <div className={styles.root}>
            <div className={styles.header}>
                <div className={styles.title}>Year of birth</div>
                <div className={styles.index}>{props.index} of {props.count}</div>
            </div>
            <div className={styles.options}>
                <InputNumber
                    type='number'
                    inputMode='numeric'
                    pattern="[0-9]*"
                    min={moment().subtract(110, 'years').year()}
                    max={moment().year()}
                    value={selected}
                    placeholder='e.g. 1988'
                    onChange={onChange}
                />
            </div>
        </div>
    );

}