import 'core-js';
import 'isomorphic-fetch';
import './index.css';

import React from 'react';
import ReactDOM from 'react-dom';
import Amplify, {Auth} from "aws-amplify";
import smoothscroll from 'smoothscroll-polyfill';
import {MemoryRouter} from "react-router-dom";
import {Route} from "react-router";
import {Provider} from "react-redux";
import createStore from '../src/redux/store';
import App from './modules/App';
import config from "./config";

smoothscroll.polyfill();

const AUTHED_APIS = Object.keys(config.apis)
    .map(apiKey => ({
        name: apiKey,
        endpoint: config.apis[apiKey].URL,
        region: config.DEFAULT_REGION,
        custom_header: async () => ({Authorization: (await Auth.currentSession()).idToken.jwtToken})
    }));

Amplify.configure({
    Auth: {
        mandatorySignIn: true,
        region: config.DEFAULT_REGION,
        userPoolId: config.cognito.USER_POOL_ID,
        identityPoolId: config.cognito.IDENTITY_POOL_ID,
        userPoolWebClientId: config.cognito.APP_CLIENT_ID
    },
    API: {
        endpoints: [
            ...AUTHED_APIS
        ]
    },
    Storage: {
        region: config.DEFAULT_REGION,
        bucket: config.s3.BUCKET,
        identityPoolId: config.cognito.IDENTITY_POOL_ID
    }
});

if (!Array.prototype.flatMap) {
    const concat = (x, y) => x.concat(y);
    const flatMap = (f, xs) => xs.map(f).reduce(concat, []);

    /*eslint no-extend-native: ["off", { "exceptions": ["Object"] }]*/
    Array.prototype.flatMap = function (f) {
        return flatMap(f, this)
    };
}

ReactDOM.render(
    <Provider store={createStore()}>
        <MemoryRouter>
            <Route component={App}/>
        </MemoryRouter>
    </Provider>
    , document.getElementById('root'));