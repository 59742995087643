import styles from "./MobileAnswers.module.less";

import {Slider} from "antd";
import React, {useState} from "react";

const COLORS = ['#EC568C', '#EE7F7E', '#FFA75E', '#FFCC30', '#A6D275', '#61D38A', '#1CD59F'];

export default function MobileAnswers(props) {

    const [selected, setSelected] = useState(props.selected);

    const selectAnswer = (value) => {
        props.onChange(value);
        setSelected(value);
    }

    const marks = {...[...Array(7).keys()].flatMap(value => ({label: <div style={{'--color': COLORS[value]}}/>}))};
    return (
        <Slider
            marks={marks}
            dots={false}
            included={false}
            step={1}
            min={0}
            max={6}
            tipFormatter={null}
            className={styles.mobileAnswers}
            value={selected}
            onChange={selectAnswer}
            style={{'--selectedColor': COLORS[selected]}}
        />
    );

}