import styles from './ProfileOption.module.less';

import React, {useState} from "react";
import {Button} from "antd";

export default function ProfileOption(props) {

    const [selected, setSelected] = useState(props.selected);

    const select = (optionId) => {
        setSelected(optionId);
        props.callback(props.id, optionId);
    }

    return (
        <div className={styles.root}>
            <div className={styles.header}>
                <div className={styles.title}>{props.title}</div>
                <div className={styles.index}>{props.index} of {props.count}</div>
            </div>
            <div className={styles.options}>
                {props.data.map(option => (
                    <Button
                        key={option.id}
                        className={option.id === selected ? styles.selected : ''}
                        onClick={() => select(option.id)}
                    >
                        {option.name || option.title}
                    </Button>
                ))}
            </div>
        </div>
    );

}