import styles from './Spinner.module.less';

import React, {Component} from "react";
import {Spin} from "antd";

export class Indicator extends Component {

    render() {
        return (
            <span className="ant-spin-dot ant-spin-dot-spin">
                <i className="ant-spin-dot-item" style={{backgroundColor: `${this.props.color ? this.props.color : '#FFFFFF'}`}}/>
                <i className="ant-spin-dot-item" style={{backgroundColor: `${this.props.color ? this.props.color : '#70CDE9'}`}}/>
                <i className="ant-spin-dot-item" style={{backgroundColor: `${this.props.color ? this.props.color : '#D972B7'}`}}/>
                <i className="ant-spin-dot-item" style={{backgroundColor: `${this.props.color ? this.props.color : '#FFB12E'}`}}/>
            </span>
        );
    }

}

class Spinner extends Component {

    render() {
        return (
            <div className={styles.container}>
                <Spin className={styles.spinner}
                      size='large'
                      indicator={<Indicator color={this.props.color}/>}
                />
            </div>
        );
    }

}

export default Spinner;