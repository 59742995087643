import styles from './Dashboard.module.less';

import React, {Component} from 'react';
import {Button} from 'antd';
import {withRouter} from "react-router";
import {NavLink} from 'react-router-dom';
import dashboardCharacter from './assets/dashboard-character.svg';
import logo from '../assets/SpeakUp_LOGO.svg';

class Dashboard extends Component {

    constructor(props) {
        super(props);

        this.state = {
            welcomeBackground: props.isMobile ? '#FFB12E' : '#EDEDF5',
            welcomeFont: props.isMobile ? '#FFFFFF' : '#808080',
        };
    }

    componentDidMount() {
        sessionStorage.clear();
        console.log('refreshed state')
    }

    questionsButton = () => (
        <div data-cy='actions'>
            <NavLink to={`/questions`} className={styles.action}>
                <Button
                    shape='round'
                >{`Let's go`}</Button>
            </NavLink>
        </div>
    );

    renderSubheaderText = () => (
        <h3>Are you ready to reflect on yesterday?</h3>
    );

    renderUsername = () => (
        <h1 className={styles.userName}>
            <div>Hello</div>
        </h1>
    );

    render() {
        return (
            <div className={styles.dashboard}>
                <div className={styles.layout}>
                    <div className={styles.left}>
                        <div className={styles.content}>
                            {this.renderUsername()}
                            {this.renderSubheaderText()}
                            {this.questionsButton()}
                        </div>
                        <div className={styles.logo}>
                            <img alt='' src={logo} />
                        </div>
                    </div>
                    <div className={styles.image}>
                        <img alt='' src={dashboardCharacter}/>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(Dashboard);