import {withRouter} from "react-router";
import styles from './Summary.module.less';

import React, {Component} from 'react';
import {Button} from 'antd';
import {Storage} from "aws-amplify";
import {NavLink} from 'react-router-dom';
import sizes from 'react-sizes';
import isNull from 'lodash/isNull';
import isEmpty from 'lodash/isEmpty';
import Api from '../../api/Api';
import TCup from '../TCup';
import Spinner from '../../utils/Spinner/Spinner';
import withCategories from "../../../redux/hoc/withCategories";

const MAX_CATEGORY_SCORE = 6;
const TCUP_COLORS = ['#70CDE9', '#D972B7', '#FFB12F'];

class Summary extends Component {

    constructor(props) {
        super(props);

        this.state = {
            sessionId: sessionStorage.getItem('sessionId'),
            scores: this.props.location.state?.scores,
            recognition: this.props.location.state?.recognition,
            feedback: this.props.location.state?.feedback
        };
    }

    async componentDidMount() {
        if (this.state.sessionId) {
            if (!this.state.recognition || !this.state.scores) {
                try {
                    const promises = [];

                    promises.push(Api.getRecognitionLevel(this.state.sessionId)
                        .then((recognition) => Storage.get(recognition.image_url))
                        .then(fetch)
                        .then(response => response.json())
                        .catch(() => this.setState({recognition: null}))
                    );

                    promises.push(Api.getSessionAnswers(this.state.sessionId));

                    const [recognition, answers] = await Promise.all(promises);

                    this.setState({
                        recognition,
                        scores: this.getCategoriesScores(this.props.categories, answers)
                    });
                } catch (err) {
                    this.props.history.push('/');
                }
            }
        } else {
            this.props.history.push('/');
        }
    }

    findCategoryBySubcategoryId(categories, subcategoryId) {
        const foundCategory = categories.find((category) => {
            return category.Subcategories.find((subcategory) => subcategory.id === subcategoryId);
        });
        return foundCategory ? foundCategory.id : null;
    }

    getCategoryScore(categoryId, categories, answers) {
        if (answers && answers.length !== 0) {
            const categoryAnswers = answers.filter((answer) => categoryId === this.findCategoryBySubcategoryId(categories, answer.subcategory_id));
            const score = categoryAnswers.map(answer => answer.calculated_value).reduce((x, y) => x + y);
            return score / MAX_CATEGORY_SCORE * 100;
        } else {
            return 0;
        }
    }

    getCategoriesScores(categories, answers) {
        return categories.map((category) => ({
            id: category.id,
            title: category.name,
            value: this.getCategoryScore(category.id, categories, answers),
            color: TCUP_COLORS[category.id - 1],
            weight: category.weight.weight
        }));
    }

    renderHeader = () => (
        <div className={styles.header}>
            <h1>Nice work.</h1>
            <div className={styles.subheader}>So reflecting on yesterday, which areas do you think impact you the most?</div>
            <div className={styles.subheader}>Could that awareness be useful today?</div>
            <div className={styles.subheader}>Want to share more?</div>
        </div>
    );

    renderContent = (scores) => (
        <div className={styles.cup}>
            <div>
                <TCup scores={scores} recognitionImage={this.state.recognition}/>
                <div className={styles.legend}>
                    <div className={styles.health}>Health</div>
                    <div className={styles.happiness}>Happiness</div>
                    <div className={styles.success}>Success</div>
                </div>
            </div>
        </div>
    );

    updateProfile = () => {
        this.props.history.push({
            pathname: '/profile',
            state: {
                scores: this.state.scores,
                recognition: this.state.recognition,
                feedback: this.state.feedback
            }
        });
    }

    addFeedback = () => this.props.history.push({
        pathname: '/feedback',
        state: {
            scores: this.state.scores,
            recognition: this.state.recognition,
            feedback: this.state.feedback
        }
    });

    renderActions = () => (
        <div className={styles.actions}>
            <Button className={styles.feedback} onClick={this.addFeedback}>
                {this.state.feedback ? `Your feedback` : `Have your say`}
            </Button>
            <NavLink to='/dashboard'>
                <Button
                    className={styles.finished}
                >
                    Finished
                </Button>
            </NavLink>
            <Button
                className={styles.learnMore}
                onClick={this.updateProfile}
            >
                Learn more
            </Button>
        </div>
    );

    isLoading = () => (
        !this.state.scores || this.state.recognition === undefined
    );

    notAnsweredQuestions = () => (
        !isEmpty([this.state.scores, this.state.recognition].filter(isNull))
    );

    render() {
        if (this.isLoading()) {
            return (
                <Spinner/>
            );
        }
        const {scores} = this.state;
        if (this.props.isMobile) {
            return (
                <div className={styles.summary}>
                    <div className={styles.layout}>
                        {this.renderContent(scores)}
                        {this.renderHeader()}
                        {this.renderActions()}
                    </div>
                </div>
            );
        }
        return (
            <div className={styles.summary}>
                <div className={styles.layout}>
                    <div className={styles.left}>
                        <div className={styles.content}>
                            <div className={styles.first}>
                                {this.renderHeader()}
                                {this.renderActions()}
                            </div>
                        </div>
                    </div>
                    <div className={styles.right}>
                        {this.renderContent(scores)}
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = ({width}) => ({
    isMobile: width < 960
})

export default sizes(mapStateToProps)(withCategories(withRouter(Summary)));