import {API} from 'aws-amplify';

const API_USERS_NAME = 'usersApi';
const API_QUESTIONS_NAME = 'questionsApi';
const API_COMMENTS_NAME = 'commentsApi';

export default class Api {

    static getDailyQuestions() {
        return API.get(API_QUESTIONS_NAME, `/questions`);
    }

    static saveExtraAnswer(questionId, value) {
        const data = {
            body: {
                questionId,
                value
            }
        };
        return API.post(API_QUESTIONS_NAME, '/questions/extra/answer', data)
    }

    static saveAnswers(sessionId, answers) {
        const data = {
            body: {
                sessionId,
                answers,
            }
        };
        return API.post(API_QUESTIONS_NAME, '/questions/regular/answer', data)
    }

    static getAllCategories() {
        return API.get(API_QUESTIONS_NAME, '/categories');
    }

    static getSessionAnswers(sessionId) {
        return API.get(API_QUESTIONS_NAME, `/answers/${sessionId}`);
    }

    static createComment(sessionId, comment, rating) {
        const data = {
            body: {
                text: comment,
                value: rating
            }
        }
        return API.post(API_COMMENTS_NAME, `/comment/${sessionId}`, data);
    }

    static getProfileFeatures() {
        return API.get(API_USERS_NAME, '/profile/features');
    }

    static updateUser(sessionId, userSettings) {
        const data = {
            body: {
                occupation_id: userSettings.occupation_id,
                fitness_level_id: userSettings.fitness_level_id,
                gender_id: userSettings.gender_id,
                team_id: userSettings.team_id,
                birthyear: userSettings.birthyear
            }
        };
        return API.put(API_USERS_NAME, `/user/${sessionId}`, data)
    }

    static getRecognitionLevel(sessionId) {
        return API.get(API_QUESTIONS_NAME, `/recognition/${sessionId}`);
    }
}
