const API_URL = "https://api.dev.speakup.dev.solidstudio.io";

export default {
    DEFAULT_REGION: "eu-west-2",
    cognito: {
        USER_POOL_ID: "eu-west-2_BjXyTnFe2",
        APP_CLIENT_ID: "7e841o82tmtvtekmms6mbgnei5",
        IDENTITY_POOL_ID: "eu-west-2:ed2c83c3-4fb3-450a-a648-37d29457ce84"
    },
    s3: {
        BUCKET: "tcup-app-uploads"
    },
    apis: {
        usersApi: {
            URL: `${API_URL}/users-service`
        },
        questionsApi: {
            URL: `${API_URL}/questions-service`
        },
        commentsApi: {
            URL: `${API_URL}/comments-service`
        }
    }
};