import React from 'react';
import {withRouter} from "react-router";
import Api from "../api/Api";
import {notificationError} from "../utils/Notification/Notification";
import ExtraQuestionView from "./ExtraQuestionView";

class ExtraQuestion extends React.Component {

    submitAnswer = (questionId, value) => {
        Api.saveExtraAnswer(questionId, value)
            .then(response => sessionStorage.setItem('sessionId', response.sessionId))
            .then(() => this.props.history.push('/questions/extra/summary'))
            .catch(() => notificationError('There was a problem saving your answer. Please contact system administrator.'));
    }

    render() {
        return (
            <div>
                <ExtraQuestionView
                    key={this.props.question.id}
                    question={this.props.question}
                    callback={this.submitAnswer}
                />
            </div>
        );
    }

}

export default withRouter(ExtraQuestion);