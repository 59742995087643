import './OverallScoreSVG.css';

import React from "react";

const OverallScoreSVG = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 152 267.469">
        <g id="overall" transform="translate(-571.233 -387.836)">
            <g id="Group_104" data-name="Group 104" transform="translate(75 23)">
                <path id="Path_451" data-name="Path 451" className="cls-1"
                      d="M568.494,568.023c34.054,0,61.66-27.375,61.66-61.144s-27.606-61.143-61.66-61.143-61.66,27.374-61.66,61.143,27.606,61.144,61.66,61.144"/>
                <text id="_79" data-name="79" className="cls-2" transform="translate(568 530)">
                    <tspan textAnchor='middle'>{Math.round(props.value) || 0}</tspan>
                </text>
            </g>
            <g id="Group_105" data-name="Group 105" transform="translate(75 23)">
                <text id="Check_against_the_" data-name="Check against the " className="cls-3" transform="translate(503.815 607.865)">
                    <tspan x="0" y="0">Check against the</tspan>
                </text>
                <text id="WellBingo_numbers." data-name="WellBingo numbers." className="cls-3" transform="translate(498.602 627.305)">
                    <tspan x="0" y="0">WellBingo numbers.</tspan>
                </text>
            </g>
            <g id="Group_106" data-name="Group 106" transform="translate(75 23)">
                <text id="Your_overall_" data-name="Your overall " className="cls-4" transform="translate(496.233 389.836)">
                    <tspan x="0" y="0">Your overall</tspan>
                </text>
                <text id="Wellbeing" className="cls-4" transform="translate(508.089 423.436)">
                    <tspan x="0" y="0">Wellbeing</tspan>
                </text>
            </g>
        </g>
    </svg>
);

export default OverallScoreSVG
